.table{
    padding: 4%;
}
.img.more{
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0px;
    width: 150px;
}
.img-size{
    width: 9%;
    height: 60%;
    margin-top: 1%;
}
.table_head{
    display: flex;
    justify-content: space-between;
}
.venuelist{
    display: flex;
    justify-content: space-between;
}
.list-hover:hover{
    color: rgb(255, 195, 30); /* Change color to whatever you want */
   cursor: pointer;
}
.appbar.css-19z1ozs-MuiPaper-root-MuiAppBar-root{
    color: antiquewhite;
    background-color: aquamarine;
}

.appbar.open .css-19z1ozs-MuiPaper-root-MuiAppBar-root{
    background-color: aquamarine;
  }
  .table-header{
    font-weight: bold;
    margin-left: 30%;
  }