.breadcrumbs {
  text-decoration: none !important;
  color: #3b2400 !important;
  font-size: 14px;
}
.breadcrumbs:hover {
  text-decoration: underline !important;
}

#mobilenumber::-webkit-inner-spin-button,
#mobilenumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#mobilenumber {
  -moz-appearance: textfield;
}

#OTP::-webkit-inner-spin-button,
#OTP::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#OTP {
  -moz-appearance: textfield;
}

/* ======= navigation ======== */

.header-2 {
  background-color: #fff !important;
  box-shadow: none !important;
  padding: 2px 0px;
}

.header-1 {
  background-color: #fff !important;
  box-shadow: rgba(240, 146, 0, 0.2) 0px 4px 12px !important;
}

.logo {
  width: 100px;
}
.logo img {
  width: 100%;
}
.policies-navigation {
  color: #715326;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.splite-header {
  display: flex;
  justify-content: space-between;
}
/* .css-hyum1k-MuiToolbar-root {
  min-height: 54px !important;
} */

.nav-btn .navigate-btn-hover {
  color: #3b2400;
  text-align: center;
  font-family: "Poppins-SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;

  width: 100px;
  transition: 0.3s;
  display: grid;
  place-items: center;
  position: relative;
}
.navigate-btn-hover-active {
  color: #f5aa35;
  text-align: center;
  font-family: "Poppins-SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;

  width: 100px;
  transition: 0.3s;
  display: grid;
  place-items: center;
  position: relative;
  cursor: pointer;
}

.nav-btn .navigate-btn-hover:hover {
  color: #f5aa35;
  cursor: pointer;
}

.nav-btn {
  display: flex;
  gap: 10px;
  align-items: center;
}

.header-btn-login {
  display: flex;
  gap: 10px;
}

.header-btn-login button {
  padding: 0px !important;
}

.header-btn-login button:nth-child(1) {
  border-radius: 50px;
  background-color: #ffbf5a;
  box-shadow: none;
  width: 100px;
  height: 35px;
  color: #fff;
  text-align: center;
  font-family: "Poppins-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.header-btn-login button:nth-child(2) {
  background-color: #f09200;
  box-shadow: none;
  color: #fff;
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}

.header-content-split {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 6px;
  /* height: 40px; */
  padding: 2px 24px;
}

.css-hyum1k-MuiToolbar-root {
  min-height: 50px;
}

.sentctions {
  display: flex;
  justify-content: flex-end;
  /* column-gap: 40px; */
}

.header-content-split div {
  color: #3b2400;

  text-align: center;
  font-family: "Poppins-Medium";
  font-size: 14px;
  font-style: normal;
}
.header-content-split div:nth-child(2) {
  display: flex;
  justify-content: center;
}

.header-content-split div img {
  width: 20px;
  margin-right: 5px;
}
.header-content-split div {
  display: flex;
}

/* ======homeimg====== */

.hero-img-block img {
  width: 100%;
  object-fit: cover;
}
.hero-img-block {
  position: relative;
}

.overlay {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, #000 100%);
  bottom: 4px;
  height: 100%;
  width: 100%;
}

.search-content {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, -10%);
  text-align: center;
}

.search-content h3 {
  color: #3b2400;
  font-family: "PlayfairDisplay-Bold";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.44px;
  margin: 0px;
  text-shadow: 2px 2px 5px rgba(236, 233, 233, 0.5);
}

.search-content p {
  color: #fff;
  text-align: center;
  font-family: "Poppins-Medium";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
  margin: 3px;
}

.search-input {
  display: flex;
  background-color: white;
  border-radius: 5px;
  margin-top: 35px;
  position: relative;
}
.input-field-search {
  display: flex;
}
.search-btn {
  width: 150px;
  background-color: #f5aa35 !important;
  color: #fff !important;
  font-family: "Poppins-Medium" !important;
  font-size: 14px !important;
  font-style: normal !important;
  line-height: normal !important;
}

.img-size-search img {
  width: 20px;
}
.img-size-search {
  display: grid;
  place-items: center;
}

.btns-for-services {
  position: absolute;
  top: -40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 10px;
  z-index: -1;
}

.btns-for-services .seach-btns {
  /* background-color: #f09200; */
  background-color: #ff8743;
  height: 32px;
  width: 150px;
  padding: 5px;
  border-radius: 5px 5px 0px 0px;
  cursor: pointer;
  transition: 0.3s;
}

.btns-for-services .seach-btns-active {
  background-color: #f5aa35;
  height: 32px;
  width: 150px;
  padding: 5px;
  border-radius: 5px 5px 0px 0px;
  cursor: pointer;
  transition: 0.3s;
}
.btns-for-services .seach-btns-active:hover {
  background-color: #f5aa35;
  opacity: 0.9;
}

.btns-for-services .seach-btns:hover {
  background-color: #f5aa35;
}

.btns-for-services .seach-btns text,
.btns-for-services .seach-btns-active text {
  color: #fff;
  text-align: center;
  font-family: "Poppins-Medium";
  font-size: 14px;
  font-style: normal;
}

.details {
  padding: 65px 0px;
  background-color: #f5f5f5;
}
.details-block-split {
  display: grid;
  grid-template-columns: 16% 16% 16% 16% 16% 16%;
  gap: 9px;
}

.details-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-box-details {
  width: 150px;
  height: 150px;
}
.img-box-details img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.details-box h3,
.venues-place-img-content h3 {
  color: #5c3800;
  text-align: center;
  font-family: "KaiseiHarunoUmi-Bold";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px;
}

.title-details {
  margin-top: 10px;
}

.details-box p {
  color: #887352;

  text-align: center;
  font-family: "Poppins-Regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 300px;
  margin: 5px 0px;
}

.venues-block {
  padding-bottom: 10px;
}

.common-header-title {
  text-align: center;
  padding: 25px 0px;
}

.common-header-title h3 {
  color: #3b2400;

  font-family: "Poppins-Medium";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 5px 0px;
}

.common-header-title p {
  color: #000;
  margin: 5px 0px;
  text-align: center;
  font-family: "Poppins-Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 10px;
}

.split-venues-box {
  display: grid;
  grid-template-columns: 31% 31% 31%;
  gap: 40px;
}

.img-venue {
  height: 200px;
  overflow: hidden;
  border-radius: 6px;
}
.img-venue img {
  width: 100%;
  border-radius: 6px;
  height: 100%;
  transition: 0.3s;
}
.venues-box {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  transition: 0.3s;
}
.venues-box:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px 1px rgba(245, 170, 53, 0.5);
}
.venues-box:hover .venue-content h3 {
  color: #f5aa35;
}
.venues-box:hover .img-venue img {
  transform: scale(1.1);
}

.bage-name-venues {
  background-color: #ef510a;
  top: -30%;

  margin-left: 60%;
  transform: translate(48%, 67%);
  padding: 0px 5px;
  color: #fff;
  font-family: "Poppins-Regular";
  text-transform: uppercase;
  font-size: 13px;
  width: 80px;
  text-align: center;
  border-radius: 10px 0px 0px 10px;
}
.bage-name-venues-loding {
  top: -30%;

  margin-left: 60%;
  transform: translate(48%, 67%);
  padding: 0px 5px;
  color: #fff;
  font-family: "Poppins-Regular";
  text-transform: uppercase;
  font-size: 13px;
  width: 80px;
  text-align: center;
  border-radius: 10px 0px 0px 10px;
}
.venue-content {
  padding: 15px;
  /* position: relative; */
}
.venue-content h3 {
  margin: 5px 0px;
  margin-top: 0px;
  color: #3b2400;
  font-family: "Poppins-Medium";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Show ellipsis for overflowed text */
}

.venue-para1,
.venue-para2,
.venue-para3 {
  margin-top: 5px;
}

.venue-para1,
.venue-para2 {
  display: flex;
  align-items: end;
}

.venue-para1 text {
  color: #333;

  font-family: "Poppins-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.venue-para1 span:nth-child(1) {
  color: #717171;

  font-family: "Poppins-Medium";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.venue-para1 span {
  margin: 0px 3px;
}
.venue-para1 .lastword-venue {
  color: #665c4b;

  font-family: "Poppins-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.venue-para2 {
  color: #665c4b;

  font-family: "Poppins-Medium";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.venue-para3 {
  color: #492d01;

  font-family: "Poppins-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.venue-para3 span {
  color: #353535;

  font-family: "Poppins-SemiBold";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px 4px;
}
.venue-para3 text {
  color: #937a53;

  text-align: center;
  font-family: "Poppins-Medium";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.venue-all-btn {
  text-align: center;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.venue-all-btn .btn-venue-done {
  color: #3b2400;
  width: 200px;
  cursor: pointer;
  font-family: "Poppins-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 35px;
  border: 1px solid #8b7a5f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Vendors-block .common-header-title {
  padding-bottom: 0px;
}

.slide-box {
  padding: 30px 0px !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px !important;
  background-color: rgba(245, 170, 53, 31%);
  padding: 10px 15px;
  border-radius: 50%;
  color: rgb(241, 161, 31);
}

.mySwiper {
  padding: 0px 30px !important;
  width: 84.5%;
}

.vendors-btns-box {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.vendors-btns-box text {
  border: 1px solid #ffd38f;
  padding: 5px 10px;
  color: #3b2400;
  font-family: "Poppins-Medium";
  border-radius: 5px;
}

.Vendors-block .venue-all-btn {
  padding-top: 40px;
}

.hotels-block .common-header-title {
  padding-bottom: 0px;
}
.hotels-block .venue-all-btn {
  padding: 0px 0px !important;
}

.img-center-airline {
  display: grid;
  place-items: center;
}

.country-block .mySwiper {
  padding: 0px 25px !important;
}

.country-block .swiper-button-prev:after,
.country-block .swiper-button-next:after {
  font-size: 20px !important;
  background-color: rgba(245, 170, 53, 31%);
  padding: 10px 15px;
  border-radius: 50%;
  color: rgb(241, 161, 31);
}

.country-block .venues-box-1 {
  position: relative;
}

.country-block .venues-box-1 .titilebar-for-country {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Poppins-SemiBold";
  color: white;
  width: 100%;
  text-align: center;
  font-size: 16px;
  text-shadow: 4px 4px 5px rgb(17, 17, 17), 0 0 3em rgb(15, 15, 15),
    0 0 0.4em rgb(20, 20, 20);
}

.country-block .common-header-title {
  padding-bottom: 0px;
}

.country-block .slide-box .venues-box {
  background: transparent !important;
  width: 180px;
}

.footer-block {
  padding: 10px 0px;
  background-color: #fce8c6;
}

.footer-block1 {
  display: flex;
  justify-content: space-around;
}

.country-block .venue-all-btn {
  padding: 10px 0px;
  padding-bottom: 40px;
}
.country-block .venue-all-btn .btn-venue-done {
  width: 130px;
}
.footer-navigation {
  gap: 30px;
  font-family: "Poppins-Regular";
  color: #715326;
  font-size: 14px;
}

.header-btn-mobile-view {
  background-color: #f5aa35 !important;
  border-radius: 5px !important;
  display: none !important;
}

.img-center-airline-mobile {
  display: none;
}
.img-center-airline {
  display: flex;
}

.mobile-search {
  display: none;
}
.customDrawerPaper {
  width: 75% !important;
}
.menu-profile {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  font-family: "Poppins-Medium";
  font-weight: 500;
  font-size: 15px;
}
.subheader {
  height: 20px !important;
  padding: 20px 15px;
  padding-bottom: 5px;
  font-family: "Poppins-Medium" !important;
  font-size: 14px !important;
}
.listitem-text-mobile {
  font-family: "Poppins-Regular" !important;
  text-transform: capitalize !important;
  font-size: 13px !important;
}
/* =================== media Query =================*/

@media (min-width: 320px) and (max-width: 720px) {
  .header-2,
  .nav-btn,
  .header-btn-login,
  .search-input {
    display: none !important;
  }
  .header-btn-mobile-view {
    display: flex !important;
  }
  .details-block-split {
    display: grid;
    grid-template-columns: 31.33% 31.33% 31.33%;
  }
  .img-box-details {
    width: 100px;
    height: 100px;
  }
  .split-venues-box {
    display: grid;
    grid-template-columns: 100%;
  }
  .details-box h3 {
    font-size: 11px;
  }
  .Vendors-block .img-venue {
    height: 185px;
  }
  .country-block .img-venue {
    height: 200px;
  }
  .footer-navigation {
    display: flex !important;
    flex-direction: column !important;
  }
  .img-center-airline-mobile {
    display: flex;
    justify-content: center;
  }
  .img-center-airline {
    display: none;
  }
  .logo {
    width: 100px;
  }
  .css-hyum1k-MuiToolbar-root {
    min-height: 64px !important;
  }
  .hero-img-block img {
    height: 100%;
  }
  .hero-img-block {
    height: 500px;
  }
  .overlay {
    bottom: 0px;
  }
  .search-content {
    width: 100%;
    bottom: 10%;
  }
  .search-content h3 {
    font-size: 25px;
  }
  .search-content p {
    font-size: 15px;
    padding: 0px 55px;
    line-height: 25px;
  }
  .maindiv-for-child-componets {
    margin-top: 53px;
  }
  .mobile-search {
    display: flex;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.14);
    position: absolute;
    bottom: -9%;
    z-index: 999;
    left: 50%;
    transform: translate(-50%, -220%);
    background-color: white;
    width: 280px;
    height: 45px;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
  }
  .mobile-search .icon-mobile-search {
    display: grid;
    place-items: center;
    padding: 0px 10px;
    background-color: #f09200;
    height: 100%;
    border-radius: 6px 0px 0px 6px;
  }
  .mobile-search div:nth-child(2) {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: #f09200;
  }
  .mobile-search div:nth-child(3) {
    display: grid;
    place-items: center;
    padding: 0px 10px;
  }

  .box-container {
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }
  .policies-navigation {
    display: flex !important;
    flex-direction: column-reverse !important;
    justify-content: space-around;
    text-align: center;
  }

  .policies-navigation text {
    margin-top: 20px;
  }
  .footer-block1 {
    display: block;
  }
}

@media (min-width: 540px) and (max-width: 720px) {
  .overlay {
    width: 100%;
  }
  .img-venue-img {
    margin-right: 10px;
  }
}

.footer {
  padding: 0 20px;
  background-color: #fce8c6;
}

.link {
  color: #715326;
  text-decoration: none;
  margin-left: 8px;
}

.footerLogo {
  height: 100px;
  width: 200px;
}

.text {
  cursor: pointer;
  color: #715326;
}

.logo-container {
  margin-top: 10px;
  text-align: center;
}

.policies div {
  margin-top: 10px;
}

.contact div {
  margin-top: 10px;
}

.copyright {
  text-align: center;
  color: #715326;
  font-family: "Poppins-Regular";
  font-size: 14px;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .MuiGrid-root.gridContainer {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
}
