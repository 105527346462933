.hero-img-block-2 {
  position: relative;
}

.hero-img-block-2 img {
  width: 100%;
}

.venuespage-search-content {
  position: absolute !important;
  bottom: 5% !important;
  left: 50% !important;
  transform: translate(-50%, -40%) !important;
  text-align: center !important;
  /* width: 90% !important; */
}
.venues-place-img-content {
  display: "flex";
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.venues-place-img img {
  width: 100%;
  border-radius: 50%;
}

.places-block .mySwiper {
  width: 85%;
}

.places-block .swiper-button-prev:after,
.places-block .swiper-button-next:after {
  font-size: 20px !important;
  background-color: rgba(245, 170, 53, 31%);
  padding: 10px 15px;
  border-radius: 50%;
  color: rgb(241, 161, 31);
  top: -50%;
  position: absolute;
}

.places-block .swiper-button-next:after {
  left: -15%;
}
.places-block .swiper-button-prev:after {
  right: -15%;
}

.venues-place-img-content h3:nth-child(1) {
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-weight: 600;
  color: #3b2400;
}
.venues-place-img-content h3:nth-child(2) {
  color: #6c5023;
  font-family: "Poppins-Regular";
  font-size: 13px;
  font-weight: 500;
}

.places-block .common-header-title {
  padding-bottom: 0px;
}
.Listings-block .common-header-title {
  padding-top: 0px;
  margin-top: 40px;
}

.Listings-block .split-venues-box .venues-box .venue-para2 text,
.Best-Venues-block .split-venues-box .venues-box .venue-para2 text {
  color: #937a53;
  text-decoration: underline;
  font-family: "Poppins-Regular";
  font-weight: 500;
  margin-left: 15px;
}

.pagination-part {
  padding: 50px 0px;
  display: flex;
  justify-content: center;
}

.btn-pagnation {
  color: #f5aa35 !important;
}

/* ============ venuesdetailspage ================ */

.path-breadcrumb {
  padding: 30px 0px;
  padding-bottom: 10px;
}

.venues-details-splited {
  display: grid;
  grid-template-columns: 60% 38%;
  gap: 2%;
}
.img-venues-details-main {
  position: relative;
}
.img-venues-details-main img {
  width: 100%;
  border-radius: 8px;
}

.imgbar-venues-details {
  display: flex;
  gap: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: absolute;
  bottom: -10%;
  left: 50%;
  transform: translate(-50%, -45%);
  padding: 10px;
  border-radius: 6px;
}
.imgbar-venues-details text {
  color: #3b2400;
  font-family: "Poppins-Medium";
  width: 80px;
  text-align: center;
  font-size: 15px;
}
.second-box-venues-details {
  margin-top: 40px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 4px;
}

.second-box-venues-details h1 {
  font-size: 23px;
  font-family: "Poppins-Medium";
  color: #3b2400;
  font-weight: 500;
  margin: 5px 0px;
}
.second-box-venues-details p {
  font-family: "Poppins-Regular";
  color: #3b2400;
  font-size: 14px;
  margin: 10px 0px;
}
.second-box-venues-details p:nth-child(2) {
  margin-bottom: 0px !important;
}
.third-box-veneus-details {
  margin: 30px 0px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 4px;
}

.third-box-veneus-details-split {
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 4%;
  row-gap: 15px;
}

.third-box-veneus-details .third-box-content-title h3 {
  font-family: "Poppins-SemiBold";
  color: #3b2400;
  font-weight: 500;
  margin: 5px 0px;
  margin-bottom: 0px;
}

.third-box-veneus-details .third-box-content-title text {
  font-family: "Poppins-Regular";
  color: #766344;
  font-size: 14px;
}
.fourth-box-veneus-details {
  margin: 30px 0px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 60% 38%;
  gap: 11px;
}
.fourth-box-veneus-details .fourth-box-1 h1 {
  color: #3b2400;
  font-family: "Poppins-SemiBold";
  font-size: 20px;
  margin: 0px;
}
.reviw-rating-splited {
  display: flex;
  gap: 10px;
}

.reviw-rating-splited .progressdiv {
  flex: 1;
}
.reviw-rating-splited .progressdiv2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins-Medium";
  color: #3b2400;
}

.reviw-rating-splited .progressbar {
  display: flex;
  align-items: center;
  gap: 10px;
}
.reviw-rating-splited .progressbar text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3b2400;
  font-family: "Poppins-Medium";
  font-size: 18px;
  gap: 6px;
}

.fourth-box-2 {
  padding: 0px 10px;
}

.rating-points-part2 {
  display: flex;
}
.rating-points-part2 text {
  font-family: "Poppins-Medium";
  color: #3b2400;
  font-weight: 500;
}
.rating-points-part2 .star-img {
  position: relative;
}

.rating-points-part2 .points-mark {
  position: absolute;
}

.fourth-box-2 .update-text-fourthbox {
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: #3b2400;
  font-size: 13px;
  margin-top: 4px;
}

.Reviews-inputs-fourth-box-2 {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.submit-review-btn {
  background-color: #f5aa35 !important;
  border-radius: 8px !important;
  color: #fff !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
  font-family: "Poppins-Medium" !important;
  font-weight: 500 !important;
}

.fifth-box-venues-details-1 {
  display: flex;
  gap: 10px;
}

.fifth-box-venues-details-1 .review-person-block {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 6px;
  padding: 15px;
  flex: 1 1;
  height: 255px;
  overflow: hidden;
}

.fifth-box-venues-details-1
  .review-person-block
  .header-name-for-review-person {
  display: flex;
  gap: 15px;
  align-items: center;
}

.review-person-block .header-name-for-review-person .person-name-1 div {
  font-family: "Poppins-Medium";
  color: #3b2400;
  background-color: #ffcb79;
  width: 50px !important;
  border-radius: 50px;
  height: 50px !important;
  display: grid;
  place-items: center;
  font-size: 26px;
}
.review-person-block .header-name-for-review-person .person-name-2 {
  line-height: 23px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.review-person-block .header-name-for-review-person .person-name-2 h3 {
  margin: 0px;
  font-family: "Poppins-Medium";
  color: #3b2400;
  font-weight: 500;
}
.review-person-block .header-name-for-review-person .person-name-2 text {
  font-family: "Poppins-Medium";
  color: #806a48;
  font-weight: 500;
  font-size: 12px;
}

.review-person-block .rating-star {
  padding: 10px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.review-person-block .rating-star text {
  font-family: "Poppins-Medium";
  color: #533508;
  font-weight: 500;
}

.title-review-person-block h3 {
  margin: 0px;
  font-family: "Poppins-Medium";
  font-weight: 500;
  color: #3b2400;
  font-size: 14px;
}
.review-person-block-content {
  margin-top: 8px;
}
.review-person-block-content p {
  margin: 0px;
  font-family: "Poppins-Medium";
  font-weight: 500;
  font-size: 12px;
  color: #7e6d51;
  /* height: 220px;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;    */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 7; /* Number of lines to show */
  text-overflow: ellipsis;
}

.review-person-block:nth-child(2) .person-name-1 div {
  background-color: #cba3ff !important;
}
.review-person-block:nth-child(3) .person-name-1 div {
  background-color: #ff985f;
}

.fqa-block-venues-details-1 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 6px;
  padding: 30px;
  margin-top: 30px;
}
.fqa-block-venues-details-1 h3 {
  font-family: "Poppins-SemiBold";
  font-size: 500;
  color: #3b2400;
  margin: 0px;
}

.fqa-block-venues-details-1 .fqa-inpusts {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 15px;
}
.fqa-block-venues-details-1 .fqa-inpusts label {
  font-family: "Poppins-SemiBold";
  color: #3b2400;
  font-weight: 500;
}

.mapfor-location-venues-details-1 {
  margin: 30px 0px;
}

.frist-box-venues-details-2 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 6px;
  width: 100%;
}

.simala-img-block-venues-details-1 {
  margin-top: 25px;
}

.simala-img-block-venues-details-1 h3 {
  margin: 0px;
  margin-bottom: 6px;
  font-family: "Poppins-SemiBold";
  font-weight: 500;
  color: #3b2400;
}
.Similar-block {
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 25px;
}

.mapfor-location-venues-details-1 h3 {
  margin: 0px;
  margin-bottom: 15px;
  font-family: "Poppins-SemiBold";
  font-weight: 500;
  color: #3b2400;
}

.frist-box-venues-details-2 h3 {
  color: #3b2400;
  font-family: "Poppins-Medium";
  font-weight: 500;
  margin: 3px 0px;
  margin-bottom: 6px;
}
.frist-box-venues-details-2 text {
  display: flex;
  align-items: center;
  column-gap: 3px;
  font-family: "Poppins-Medium";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
  color: #52442e;
}
.frist-box-venues-details-2 p {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin: 5px 0px;
  font-size: 12px;
  font-family: "Poppins-Regular";
  color: #665c4b;
}
.frist-box-venues-details-2 .range-content {
  font-family: "Poppins-SemiBold";
  font-size: 22px;
  color: #3b2400;
  display: flex;
  align-items: center;
}
.frist-box-venues-details-2 .range-content span {
  color: #f5aa35;
  font-size: 22px;
}
.spaces-fristbox {
  padding: 10px 20px;
  padding-bottom: 2px;
}
.btns-actions {
  display: flex;
}
.btns-actions div {
  flex: 1;
  padding: 15px 0px;
  border: 1px solid rgba(0, 0, 0, 0.19);
  font-family: "Poppins-Regular";
  color: #65481b;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.rating-points {
  position: absolute;
  top: 3%;
  right: 3%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.star-img {
  position: relative;
}
.star-img img {
  width: 45px;
}
.points-mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  font-family: "Poppins-Medium";
}

.star-content text {
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
}
.star-content div {
  font-family: "Poppins-Regular";
  color: #544a3b;
  font-size: 13px;
}

.second-box-venues-details-2 {
  box-shadow: rgba(255, 112, 32, 0.5) 0px 1px 4px;
  border-radius: 6px;
  position: relative;
  margin-top: 30px;
  padding: 15px 20px;
}
.second-box-venues-details-2 h3 {
  font-family: "Poppins-Medium";
  color: #3b2400;
  font-weight: 500;
  margin: 0px;
}

.input-second-box-venues-details-2 {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 45% 45%;
  gap: 15px;
  column-gap: 37px;
}

.funtion-block-details-2 {
  display: flex;
  margin-top: 20px;
  gap: 35px;
}
.one-details-block,
.two-details-block {
  flex: 1;
}

.funtion-block-details-2 text {
  font-family: "Poppins-Medium";
  color: #3b2400;
  font-weight: 500;
}
.radiobtn-Functiontime {
  display: grid !important;
  grid-template-columns: 49% 49% !important;
  align-items: center;
  gap: 8px;
}

.labe-radio {
  font-size: 15px;
  font-family: "Poppins-Regular";
  color: #6b5b40;
}
.call-action-for-details-2 {
  margin-top: 15px;
  display: flex;
  gap: 25px;
}

.content-btn,
.icon-btn {
  background-color: #f5aa35 !important;
  border-radius: 10px !important;
}
.icon-btn {
  padding: 10px !important;
}
.content-btn {
  flex: 1 !important;
  box-shadow: none !important;
  font-family: "Poppins-Medium" !important;
  text-transform: capitalize !important;
  font-size: 17px !important;
}
.second-box-venues-details-2 .Availability-date {
  display: flex;
  align-items: center;
  gap: 10px;
}

.second-box-venues-details-2 .Availability-date div:nth-child(2) text {
  font-family: "Poppins-Medium";
  color: #3b2400;
  font-weight: 500;
}
.second-box-venues-details-2 .Availability-date div:nth-child(2) div {
  font-family: "Poppins-Regular";
  color: #65481b;
  font-size: 13px;
}

.second-box-venues-details-2 .Availability-date div:nth-child(2) {
  line-height: 18px;
}

.position-fixed-side-content {
  position: fixed;
  width: 32%;
  top: 25%;
  transform: translate(10px, 2px);
  transition: 0.5s;
}

/*===================== vendors main page ===========================*/

.vendors-search-content h3 {
  color: #fff !important;
  text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.vendors-search-content {
  bottom: 8%;
}

.vendors-overlay {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000 100%);
}

.vendors-catagaious .catagaious-split {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 23px;
  margin-bottom: 40px;
}
.vendors-catagaious .catagaious-split .vendors-catagaious-box {
  border: 1px solid #715426;
  padding: 10px;
  border-radius: 8px;
}
.vendors-catagaious-box-img {
  position: relative;
}
.vendors-catagaious-box-img img {
  width: 100%;
  border-radius: 8px;
}

.vendors-catagaious-box-content h3 {
  font-family: "KaiseiHarunoUmi-Bold";
  font-size: 18px;
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}

.vendors-catagaious-box-content p {
  margin: 0px;
  font-family: "KaiseiHarunoUmi-Medium";
  font-size: 13px;
  margin: 8px 0px;
  color: #715426;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.vendors-catagaious-icon {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 10%);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffe0af;
  border-radius: 50px;
  border: 4px solid #fff;
}

.vendors-catagaious-icon img {
  width: 50px;
}

.rating-box-in-mobile {
  display: none;
}
.mobile-price-btn {
  display: none;
}

/* =================== media Query =================*/

@media (min-width: 320px) and (max-width: 480px) {
  .venues-details-splited,
  .third-box-veneus-details-split,
  .Similar-block,
  .fourth-box-veneus-details {
    display: grid;
    grid-template-columns: 100%;
  }
  .venues-details-box-2 {
    display: none;
  }
  .fifth-box-venues-details-1 {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  .mobile-box {
    position: absolute;
    width: 70%;
    display: none;
  }
  .imgbar-venues-details {
    width: 86%;
    gap: 2px;
    bottom: -23%;
  }
  .imgbar-venues-details text {
    font-size: 10px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rating-box-in-mobile {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 45px;
  }
  .second-box-venues-details {
    margin-top: 30px;
  }
  .spaces-fristbox {
    padding: 10px 20px;
  }
  .rating-box-in-mobile .spaces-fristbox h3 {
    color: #3b2400;
    font-family: "Poppins-Medium";
    font-weight: 500;
    margin: 3px 0px;
    margin-bottom: 6px;
  }
  .rating-box-in-mobile .spaces-fristbox text {
    display: flex;
    align-items: center;
    column-gap: 3px;
    font-family: "Poppins-Medium";
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
    color: #52442e;
  }
  .rating-box-in-mobile .spaces-fristbox p {
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin: 5px 0px;
    font-size: 12px;
    font-family: "Poppins-Regular";
    color: #665c4b;
  }
  .rating-box-in-mobile .spaces-fristbox .range-content {
    font-family: "Poppins-SemiBold";
    font-size: 15px;
    color: #3b2400;
    display: flex;
    align-items: center;
  }
  .rating-box-in-mobile .spaces-fristbox .range-content span {
    color: #f5aa35;
    font-size: 22px;
  }
  .btns-actions div {
    padding: 15px 0px;
  }
  .rating-box-in-mobile {
    position: relative;
  }
  .star-content text {
    font-family: "Poppins-Medium" !important;
    color: #3b2400;
  }
  .mobile-price-btn {
    display: flex;
    flex-direction: column;
  }
}

.modal-header {
  /* width: "300px";
  height: "300px"; */
}

@media only screen and (max-width: 300px) {
  .hero-img-block-2 img {
    width: 100%;
    height: 375px;
  }
  .homePageSearchContent {
    display: none;
  }
}

@media only screen and (min-width: 300px) {
  .hero-img-block-2 img {
    width: 100%;
    height: 375px;
    border-bottom-left-radius: 13rem;
    border-bottom-right-radius: 13rem;
  }
  .overlay {
    border-bottom-left-radius: 14rem;
    border-bottom-right-radius: 14rem;
  }
  .homePageSearchContent {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .hero-img-block-2 img {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .overlay {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .homePageSearchContent {
    display: block;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
