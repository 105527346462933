.otp-input{
    width: 60%;
    margin: 28px auto;
    display: flex;
    gap: 10px;
}
.otp-inputs{
  text-align: center;
}

    .textbox{
        width: 30px;
        padding: 10px;
        outline: none;
        text-align: center;
        margin-left: 10px;
        
    }

    .resend-otp{
        text-align: end;
    }


