HTML,body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Poppins-Medium";
  src: url("./fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("./fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-Thin";
  src: url("./fonts/Poppins-Thin.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("./fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-Black";
  src: url("./fonts/Poppins-Black.ttf");
}


@font-face {
  font-family: "PlayfairDisplay-Medium";
  src: url("./fonts/PlayfairDisplay-Medium.ttf");
}
@font-face {
  font-family: "PlayfairDisplay-Regular";
  src: url("./fonts/PlayfairDisplay-Regular.ttf");
}
@font-face {
  font-family: "PlayfairDisplay-SemiBold";
  src: url("./fonts/PlayfairDisplay-SemiBold.ttf");
}
@font-face {
  font-family: "PlayfairDisplay-ExtraBold";
  src: url("./fonts/PlayfairDisplay-ExtraBold.ttf");
}
@font-face {
  font-family: "PlayfairDisplay-Bold";
  src: url("./fonts/PlayfairDisplay-Bold.ttf");
}
@font-face {
  font-family: "PlayfairDisplay-Black";
  src: url("./fonts/PlayfairDisplay-Black.ttf");
}

@font-face {
  font-family: "KaiseiHarunoUmi-Bold";
  src: url("./fonts/KaiseiHarunoUmi-Bold.ttf");
}
@font-face {
  font-family: "KaiseiHarunoUmi-Medium";
  src: url("./fonts/KaiseiHarunoUmi-Medium.ttf");
}
@font-face {
  font-family: "KaiseiHarunoUmi-Regular";
  src: url("./fonts/KaiseiHarunoUmi-Regular.ttf");
}