.imges img {
    width: 100%;
    border-radius: 6px;
    height: 100%;
    transition: 0.3s;
  }

  .videos video {
    width: 100%;
    border-radius: 6px;
    height: 100%;
    transition: 0.3s;
  }