.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 500px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer1 {
  flex: 20% 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin-top: 335px;
  margin-left: 100px;
}

.modalContainer .footer1 button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: rgb(229, 160, 0);
  color: rgb(8, 8, 8);
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  color: rgb(0, 0, 0);
}

#cancelBtn {
  background-color: rgb(240, 143, 24);
}

.email-container {
  display: block;
  font-size: 30px;
  margin: 10%;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  text-align: center;
}

.para-task {
  text-align: center;
  align-items: center;
}
.input-1 {
  font-size: 21px;
  margin: 15px;
  display: inline-flex;
  flex-direction: row-reverse;
  padding: 7px;
}

.title-1 {
  text-align: center;
}
.error {
  color: red;
  font-size: 15px;
  text-align: center;
  margin-bottom: 100px;
}
