/* venue-card.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

.venue-content {
    display: flex;
    flex-direction: column;
  }
  
  .venue-content h3 {
    margin-left: 6px;
  }
  
  .venue-para1 {
    display: flex;
    align-items: baseline;
  }
  
  .lastword-venue {
    display: flex;
  }
  
  .star-icon {
    margin-left: auto;
  }
  
  .venue-para2 {
    display: flex;
    align-items: center;
  }
  

  
  .venue-divider {
    margin-top: 3px;
    color: #e86a71;
  }
  
.location {
  font-family: 'Poppins',sans-serif; /* Adding Popins font */
  font-weight: 500; /* Lighter font weight */
  color: #757575; /* Light grey color */
}
/* venue-card.css */

.venue-para3 {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 6px;
}

.price {
  margin-left: 5px; /* Adjust this margin as needed */
}

.know-more-btn {
  margin-left: auto;
  background-color: orange;
  margin-bottom: 20px;
}

.no-records-found{
  margin-top: 20px; /* Adjust as needed */
  margin-bottom: 20px; /* Adjust as needed */
  text-align: center;
}