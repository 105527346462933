.table{
    padding: 4%;
}
.img.more{
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0px;
    width: 150px;
}
.img-size{
    width: 9%;
    height: 60%;
    margin-top: 1%;
}

.venuelist{
    display: flex;
    justify-content: space-between;
}
.list-hover:hover{
    color: rgb(255, 195, 30); /* Change color to whatever you want */
   cursor: pointer;
}

.photo-container{
   display: flex;
   border: 2px dashed orange;
   width: 150px;
   height: 100px;
   background-color: white;
   text-align: center;
   align-content: center; 
   text-align: center;
   padding-left: 25px;
   padding-top: 14px;
}
.photo-container-add{
    border: 2px dashed orange;
    width: 150px;
    height: 100px;
    background-color: white;
    text-align: center;
    align-content: center;
    margin-left: 65px;
}
.photo-container h4{
    text-align: center;

}
.photo-container img {
    width: 100px;
    height: 100px; 
    object-fit: cover;
    margin: 5px; 
    border-radius: 5px;
  }
  
.addpicses{
    text-align: center;
    margin-left: 15px;
    font-size: large;
    
}
.uploadBtn{
    margin-top: 20px; 
  text-align: center;
  
}
/*  */
.center-container {
    display: flex;
    justify-content: center;
  }
  
  .buttouns {
    display: flex;
    justify-content: center;
    gap: 10px; /* Adjust the gap between buttons */
  }