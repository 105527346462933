/* media query */

@media only screen and (max-width: 300px) {
  .sigInHeader {
    font-size: 16px;
    text-align: center;
  }
  .signinImage {
    display: none;
  }
  .signInButtons.MuiButton-root {
    font-size: 9px;
  }
  .mainGridSignIn.MuiGrid-root {
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }
  .signInbox.MuiBox-root {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .signInCustomerButtons.MuiButton-root {
    font-size: 9px;
    width: 180px;
  }
  .maindiv-for-child-componets {
    margin-top: 64px;
    min-height: 50vh;
  }
}

@media only screen and (min-width: 300px) {
  .signInCustomerButtons.MuiButton-root {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 9px;
    width: 180px;
  }
  .sigInHeader {
    font-size: 16px;
    text-align: center;
  }
  .signinImage {
    display: none;
  }
  .signInButtons.MuiButton-root {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 10px;
  }
  .mainGridSignIn.MuiGrid-root {
    padding-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }
  .signInbox.MuiBox-root {
    padding: 15px;
  }
  .maindiv-for-child-componets {
    margin-top: 64px;
    min-height: 50vh;
  }
}

@media only screen and (min-width: 400px) {
  .signInButtons.MuiButton-root {
    font-size: 10px;
  }
  .mainGridSignIn.MuiGrid-root {
    padding-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
  }
  .signInbox.MuiBox-root {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .signInCustomerButtons.MuiButton-root {
    font-size: 10px;
    width: 180px;
  }
}

@media only screen and (min-width: 600px) {
  .signInButtons.MuiButton-root {
    font-size: 13px;
  }
  .signInCustomerButtons.MuiButton-root {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) {
  .sigInHeader {
    font-size: 20px;
  }
  .mainGridSignIn.MuiGrid-root {
    padding-top: 8.2rem;
    padding-left: 7rem;
    padding-right: 7rem;
    padding-bottom: 1rem;
  }
  .signInbox.MuiBox-root {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media only screen and (min-width: 912px) {
  .sigInHeader {
    font-size: 19px;
  }
  .mainGridSignIn.MuiGrid-root {
    padding-top: 7rem;
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .signinImage {
    display: block;
  }

  .signInButtons.MuiButton-root {
    font-size: 12px;
    width: 180px;
  }
  .signInCustomerButtons.MuiButton-root {
    font-size: 12px;
    width: 180px;
  }
  .maindiv-for-child-componets {
    margin-top: 64px;
    min-height: 85vh;
  }
}
#mobile_number::-webkit-inner-spin-button,
#mobile_number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#mobile_number {
  -moz-appearance: textfield;
}
@media only screen and (min-width: 1200px) {
  .mainGridSignIn.MuiGrid-root {
    padding-top: 7rem;
    padding-left: 14rem;
    padding-right: 14rem;
    padding-bottom: 2rem;
  }
  .signInButtons.MuiButton-root {
    font-size: 13px;
    width: 180px;
  }
  .sigInHeader {
    font-size: 22px;
  }
  .signInCustomerButtons.MuiButton-root {
    font-size: 13px;
    width: 180px;
  }
}
