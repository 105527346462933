/* .dashboard-split-dawer{
    margin-top: 60px;
} */

.VenuesDashboard-block{
    padding-top: 45px;
}

.dashboard-split-dawer{
    display: grid;
    grid-template-columns: 24% 74%;
    gap: 22px;
    padding: 20px 0px;
}

.navigation-btn-vendors-dashboard{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px 30px;
    border-radius: 6px;
    position: fixed;
    transition: 0.3s;
    top: 22%;
}

.navigation-btn-vendors-dashboard text{
    font-family: "Poppins-Medium";
    font-weight: 500;
    color:#180806;
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 10px 0px;
    transition: 0.3s;
}


.form-for-vendors{
    padding: 20px;
    padding-top: 0px;
}
.form-for-vendors h3{
   
   margin-top: 0px;
}

.box-status-vendors{
    display: flex;
}

.box-status-vendors div{
    padding: 25px 0px;
    flex: 1;
    border: 1px solid #937A53;
    display: grid;
    place-items: center;
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: #82683E;
    font-size: 14px;
}
.box-status-vendors div:nth-child(1),.box-status-vendors div:nth-child(2){
    border-right: 0px;
}

.loader-for-profile-completion{
    padding:5px;
    margin-top: 10px;
    border: 2px solid #F5AA35;
    border-radius: 5px;
    position: relative;
}
.loader-for-profile-completion .text-complation{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: "Poppins-Medium";
    color: #180806;
    font-weight: 500;
    font-size: 13px;
}
.note-box{
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 30px;
}

.note-box h3{
    margin-bottom: 5px;
}

.note-box ul{
    margin: 0px;
}
.note-box ul li{
    padding: 5px;
    font-family: "Poppins-Regular";
    color: #180806;
    font-size: 14px;
}

.from-inpust-box2-dashboard{
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 20px 30px;
}

.dashboard-personal-textfield{
    display: grid;
    grid-template-columns: 30% 70%;
}
.dashboard-personal-textfield div{
  display: flex;
}

.dashboard-personal-textfield div button{
    width: 200px;
    background-color: #F5AA35 !important;
    font-family: "Poppins-Regular";
    text-transform: capitalize;
    box-shadow: none !important;
    border-radius: 0px !important;
}
.dashboard-personal-textfield label{
    font-family: "Poppins-Medium";
    font-weight: 400;
    color: #180806;
    display: flex;
    align-items: center;
}

.number-field-dashboard .numberbtn{
    width: 100px !important;
    background-color: transparent !important;
    border: 1px solid rgb(180, 177, 177) !important;
    font-family: "Poppins-Regular" !important;
    font-weight: 500 !important;
    color: #180806;
}

.number-field-dashboard{
    display: flex;
    align-items: end;
}
.add-more-btn{
    flex:1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 9px;
}
.add-more-btn text{
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: "Poppins-Medium";
    color: #180806;
    cursor: pointer;
    transition: 0.3s;
}

.add-more-btn text:hover{
    color: #F5AA35;
    cursor: pointer;
}

.additional-details-dashboard{
    margin-top: 30px;
}
.additional-details-dashboard h3{
    font-family: "Poppins-Medium";
    color: #180806;
    font-weight: 500;
}

.raidio-btnquts{
    margin-top: 30px;
}

.raidio-btnquts label{
    font-family: "Poppins-Medium";
    font-weight: 500;
    color: #180806;
}
.sublabel{
    font-family: "Poppins-Medium" !important;
    font-weight: 500 !important;
    color: #180806 !important;
    font-size: 15px !important;
}

.radiogroup-title{
    margin-top: 10px;
}

.save-btn-dashboard button{
    font-family: "Poppins-Bold";
    color: white;
    background-color: #F5AA35 !important;
    text-transform: capitalize;
    box-shadow: none !important;
    font-size: 18px;
    font-weight: 500;
    width: 130px;
    height: 42px;
    transition: 0.3s;
}
.save-btn-dashboard button:hover{
    opacity: 0.8;
}
.save-btn-dashboard {
    margin-top: 15px;
    text-align: end;
}