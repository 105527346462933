.popup {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    animation: popupAnimation 0.5s ease forwards;
  }
  
  @keyframes popupAnimation {
    0% {
      transform: translate(-50%, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
  
  .cutout-image {
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    transition: transform 0.3s ease;
  }

  @media (min-width: 320px) and (max-width: 720px) {
    .containr-box{
      background-color: black;
    }

    .header-btn-mobile-view{
      display: flex !important;
    }
    .details-block-split{
      display: grid;
      grid-template-columns: 31.33% 31.33% 31.33%;
    }
    .img-box-details{
      width: 100px;
      height: 100px;
    }
    .split-venues-box{
      display: grid;
      grid-template-columns: 100%;
    }
    .details-box h3{
      font-size: 11px;
    }
    .Vendors-block .img-venue{
      height: 185px;
    }
    .country-block .img-venue {
      height: 200px;
    }
    .footer-navigation{
      display: flex !important;
      flex-direction: column-reverse !important;
    }           
  }
  
  .cutout-image:hover {
    transform: scale(1.1);
  }
  