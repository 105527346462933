.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.title {
  text-align: center;
  margin-top: 10px;
  color: orange;
}

.button {
  width: 100%;
  color: #060606;
  background-color: orange;
  padding: 10px;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  margin-top: 20px;
}

.button:hover {
  background-color: darkorange;
}

.input-error {
  border-color: red;
}

.error {
  color: red;
  text-align: center;
  margin-top: 10px;
}
